import httpManager from "../axios/httpManager";

export function getCrossRuleList  () {
    return httpManager.get('/crossrule/list?t=' + new Date().getTime());
}
export function getCrossRuleByGameTypeId  (id, ruletype) {
    return httpManager.get('/crossrule/get_by_gametypeid?gameTypeId=' + id + '&ruletype=' + ruletype + '&t=' + new Date().getTime())
}
export function deleteCrossRule  (id, ruletype) {
    return httpManager.get('/crossrule/delete?gameTypeId=' + id + '&ruletype=' + ruletype + '&t=' + new Date().getTime())
}
export function updateCrossRule  (rule) {
    return httpManager.post('/crossrule/update', rule);
}
export function saveCrossRule  (rule) {
    return httpManager.post('/crossrule/save', rule);
}