<template>
    <div class="main">
        <div class="search">
            <span style="font-size: 14px;font-weight: bold;color: #fff9ff">크로스룰 설정</span>
            <el-button size="mini" type="primary" style="margin-left: 10px" @click="editCrossRule({},managerConst.MODE_SAVE)">크로스룰 등록</el-button>
        </div>
        <div class="data">
            <el-collapse v-model="activeNames" accordion style="width: 100%;">
                <el-collapse-item
                        :title=" '➽' +  item.typeName + ' > ' + (item.ruletype == managerConst.CrossRule.RULE_TYPE_FULLTIME ? '풀타임' :'하프타임')"
                        v-for="(item,index) in crossRuleList" :name="index">
                    <table class="table100">
                        <tr>
                            <th style="width: 14.2%">종목</th>
                            <th style="width: 14.2%">룰타입</th>
                            <th style="width: 14.2%">크로스베팅</th>
                            <th style="width: 14.2%">동일게임크로스</th>
                            <th style="width: 14.2%"></th>
                            <th style="width: 14.2%"></th>
                        </tr>
                        <tr>
                            <td>{{item.typeName}}</td>
                            <td>
                                <span v-if="item.ruletype == managerConst.CrossRule.RULE_TYPE_FULLTIME"
                                      class="badge badge-primary">풀타임</span>
                                <span v-if="item.ruletype == managerConst.CrossRule.RULE_TYPE_HALFTIME"
                                      class="badge badge-danger">하프타임</span>
                            </td>
                            <td>
                                <span v-if="item.crossable == managerConst.ENABLE" class="badge badge-primary">가능</span>
                                <span v-if="item.crossable == managerConst.DISABLE" class="text-danger">차단</span>
                            </td>
                            <td>
                                <span v-if="item.sgcrossable == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.sgcrossable == managerConst.DISABLE" class="text-danger">차단</span>
                            </td>
                            <td>
                                <el-button size="mini" @click="editCrossRule(item,managerConst.MODE_EDIT)"
                                           type="primary">수정하기
                                </el-button>
                            </td>
                            <td>
                                <el-button size="mini" @click="deleteRule(item.gameTypeId,item.ruletype)" type="danger">삭제하기</el-button>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 14.2%">승무패 홈승,핸디캡 홈승</th>
                            <th style="width: 14.2%">승무패 홈승,핸디캡 원정승</th>
                            <th style="width: 14.2%">승무패 무,핸디캡 홈승</th>
                            <th style="width: 14.2%">승무패 무,핸디캡 원정승</th>
                            <th style="width: 14.2%">승무패 원정승,핸디캡 홈승</th>
                            <th style="width: 14.2%">승무패 원정승,핸디캡 원정승</th>
                        </tr>
                        <tr>
                            <td>
                                <span v-if="item.protoHomeHandicapHome == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.protoHomeHandicapHome == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td>
                                <span v-if="item.protoHomeHandicapAway == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.protoHomeHandicapAway == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td>
                                <span v-if="item.protoDrawHandicapHome == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.protoDrawHandicapHome == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td>
                                <span v-if="item.protoDrawHandicapAway == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.protoDrawHandicapAway == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td>
                                <span v-if="item.protoAwayHandicapHome == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.protoAwayHandicapHome == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td>
                                <span v-if="item.protoAwayHandicapAway == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.protoAwayHandicapAway == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                        </tr>
                        <!--승패 오버언더 룰-->
                        <tr>
                            <th style="width: 14.2%">승무패 홈승, 오버</th>
                            <th style="width: 14.2%">승무패 홈승, 언더</th>
                            <th style="width: 14.2%">승무패 무, 오버</th>
                            <th style="width: 14.2%">승무패 무, 언더</th>
                            <th style="width: 14.2%">승무패 원정승, 오버</th>
                            <th style="width: 14.2%">승무패 원정승, 언더</th>
                        </tr>
                        <tr>
                            <td>
                                            <span v-if="item.protoHomeOverunderOver == managerConst.ENABLE"
                                                  class="badge badge-primary">가능</span>
                                <span v-if="item.protoHomeOverunderOver == managerConst.DISABLE" class="text-danger"
                                >X</span>
                            </td>
                            <td>
                                            <span v-if="item.protoHomeOverunderUnder == managerConst.ENABLE"
                                                  class="badge badge-primary"
                                            >가능</span>
                                <span v-if="item.protoHomeOverunderUnder == managerConst.DISABLE" class="text-danger"
                                >X</span>
                            </td>
                            <td>
                                            <span v-if="item.protoDrawOverunderOver == managerConst.ENABLE"
                                                  class="badge badge-primary"
                                            >가능</span>
                                <span v-if="item.protoDrawOverunderOver == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td>
                                            <span v-if="item.protoDrawOverunderUnder == managerConst.ENABLE"
                                                  class="badge badge-primary">가능</span>
                                <span v-if="item.protoDrawOverunderUnder == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td>
                                            <span v-if="item.protoAwayOverunderOver == managerConst.ENABLE"
                                                  class="badge badge-primary">가능</span>
                                <span v-if="item.protoAwayOverunderOver == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td>
                                            <span v-if="item.protoAwayOverunderUnder == managerConst.ENABLE"
                                                  class="badge badge-primary">가능</span>
                                <span v-if="item.protoAwayOverunderUnder == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                        </tr>
                        <!--핸디, 오버언더 룰-->
                        <tr>
                            <th style="width: 14.2%">핸디 홈승, 오버</th>
                            <th style="width: 14.2%">핸디 홈승, 언더</th>
                            <th style="width: 14.2%">핸디 원정승, 오버</th>
                            <th style="width: 14.2%">핸디 원정승, 언더</th>
                            <th style="width: 14.2%"></th>
                            <th style="width: 14.2%"></th>
                        </tr>
                        <tr>
                            <td>
                                <span v-if="item.handicapHomeOverunderOver == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.handicapHomeOverunderOver == managerConst.DISABLE" class="text-danger">X</span>
                            </td>
                            <td>
                                <span v-if="item.handicapHomeOverunderUnder == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.handicapHomeOverunderUnder == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td>
                                <span v-if="item.handicapAwayOverunderOver == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.handicapAwayOverunderOver == managerConst.DISABLE" class="text-danger">X</span>
                            </td>
                            <td>
                                <span v-if="item.handicapAwayOverunderUnder == managerConst.ENABLE"
                                      class="badge badge-primary">가능</span>
                                <span v-if="item.handicapAwayOverunderUnder == managerConst.DISABLE"
                                      class="text-danger">X</span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </el-collapse-item>
            </el-collapse>

        </div>
        <el-dialog title="크로스룰 수정/등록" :visible.sync="crossRuleDialog" style="width: 100%">

            <table class="table100">
                <tr>
                    <th style="width: 14.5%">종목</th>
                    <th style="width: 14.5%">룰타입</th>
                    <th style="width: 14.5%">크로스베팅</th>
                    <th style="width: 14.5%">동일게임크로스베팅</th>
                    <th style="width: 14.5%"></th>
                    <th style="width: 14.5%"></th>
                </tr>
                <tr>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.gameTypeId">
                            <el-option v-for="item in gameTypeList" :value="item.id" :label="item.typeName">{{item.typeName}}</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.ruletype">
                            <el-option :value="managerConst.CrossRule.RULE_TYPE_FULLTIME" label="풀타임">풀타임</el-option>
                            <el-option :value="managerConst.CrossRule.RULE_TYPE_HALFTIME" label="하프타임">하프타임</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.crossable">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.sgcrossable">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th>승무패 홈승,핸디캡 홈승</th>
                    <th>승무패 홈승,핸디캡 원정승</th>
                    <th>승무패 무,핸디캡 홈승</th>
                    <th>승무패 무,핸디캡 원정승</th>
                    <th>승무패 원정승,핸디캡 홈승</th>
                    <th>승무패 원정승,핸디캡 원정승</th>
                </tr>
                <tr>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoHomeHandicapHome">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoHomeHandicapAway">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoDrawHandicapHome">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoDrawHandicapAway">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoAwayHandicapHome">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoAwayHandicapAway">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                </tr>

                <!--승무패 오버언더 룰-->
                <tr>
                    <th>승무패 홈승, 오버</th>
                    <th>승무패 홈승, 언더</th>
                    <th>승무패 무, 오버</th>
                    <th>승무패 무, 언더</th>
                    <th>승무패 원정승, 오버</th>
                    <th>승무패 원정승, 언더</th>
                </tr>
                <tr>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoHomeOverunderOver">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoHomeOverunderUnder">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoDrawOverunderOver">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoDrawOverunderUnder">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoAwayOverunderOver">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.protoAwayOverunderUnder">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                </tr>

                <!--핸디캡 오버언더 룰-->
                <tr>
                    <th>핸디캡 홈승, 오버</th>
                    <th>핸디캡 홈승, 언더</th>
                    <th>핸디캡 원정승, 오버</th>
                    <th>핸디캡 원정승, 언더</th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.handicapHomeOverunderOver">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.handicapHomeOverunderUnder">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.handicapAwayOverunderOver">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select size="mini" style="width: 120px;margin-left: 5px" v-model="crossRule.handicapAwayOverunderUnder">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                        </el-select>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
            </table>

            <div slot="footer" class="dialog-footer">
                <el-button @click="crossRuleDialog = false">취소</el-button>
                <el-button type="primary" @click="save">확인</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";

    import managerConst from "../../common/administrator/managerConst";
    import {manager} from "../../common/administrator/managerMixin";
    import {Loading} from "element-ui";

    import {
        deleteCrossRule,
        getCrossRuleList,
        saveCrossRule,
        updateCrossRule
    } from "../../network/manager/crossRuleRequest";
    import {getGameTypeList} from "../../network/manager/gameTypeRequest";
    import {updateGroup} from "../../network/manager/groupRequest";

    export default {
        name: "ManagerCrossRule",
        mixins: [manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                crossRuleDialog: false,
                activeNames: 0,
                crossRule: {gameTypeId:null},
                crossRuleList: {},
                mode: managerConst.MODE_SAVE,
                gameTypeList: [],
            }
        },
        methods: {
            editCrossRule(cr, mode) {
                this.mode = mode
                this.crossRule = cr;
                this.crossRuleDialog=true
            },
            getRuleList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getCrossRuleList().then(res => {
                    this.crossRuleList = res.data.data
                    loadingInstance.close()
                })
            },
            save() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if (this.mode == managerConst.MODE_SAVE) {
                    saveCrossRule(this.crossRule).then(res => {
                        loadingInstance.close()
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '등록이 완료되였습니다'
                            });
                            this.getRuleList()
                            this.crossRuleDialog= false
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                } else {
                    updateCrossRule(this.crossRule).then(res => {
                        loadingInstance.close()
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '업데이트 완료되였습니다'
                            });
                            this.getRuleList()
                            this.crossRuleDialog= false
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                }

            },
            deleteRule(gameTypeId,ruletype){
                this.$confirm('해당 크로스룰을 영구 삭제 하시겠습니까?', '영구삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    deleteCrossRule(gameTypeId,ruletype).then(res=>{
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '삭제 완료되였습니다'
                            });
                            this.getCrossRuleList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }


                    })

                });
            }
        },
        created() {
            this.getRuleList()
            getGameTypeList().then(res => {
                this.gameTypeList = res.data.data
            })
        },
        watch: {}
    }
</script>

<style scoped>

</style>